/* eslint-disable max-len */
import {
    Box,
    Container, Divider, Grid, Hidden, IconButton, Typography,
} from '@mui/material';
import styled from 'styled-components';
import {
    DeleteOutline, EditOutlined, Facebook,
    Pause, PlayArrow, Restore, FilterList as FilterListIcon,
    AccountBalanceWalletOutlined as AccountBalanceWalletOutlinedIcon,
    InsertChartOutlined,
} from '@mui/icons-material';

import React from 'react';

import { debounce } from '@mui/material/utils';
import { LoggedInUser } from '../../../types/auth.types';
import { JobViewModel, PostedJob, jobTypes } from '../../../types/jobs.types';
import Pagination from '../components/Pagination/Pagination';
import Anchor from '../foundation/Anchor';
import Button, { GoldenButton } from '../foundation/Button';
import {
    BGContainer, ShadowBGContainer, SecondaryBox, YellowTypography, GreenTypography,
} from '../foundation/Containers';
import CustomBR from '../foundation/CustomBR';
import DateFormat from '../foundation/DateFormat';
import { CenteredFlex, Flexbox, VCenteredFlex } from '../foundation/Flex';
import Page from '../layout/base.page';
import TextField from '../foundation/TextField';
import Select from '../foundation/Select/Select';
import UserNotification from '../components/UserNotification';
import {
    deleteJob, editJob, getEmployerJobs,
} from '../../api/job.api';
import { importExternalJobs } from '../../api/extrenalJobs';
import Tooltip from '../foundation/Tooltip';
import DialogWithHeader from '../foundation/DialogWithHeader';
// import BuyPromotedJobs from '../containers/BuyPromotedJobs';
import BuyJobs from '../containers/BuyJobs';
import ChangeJobTimeModal from '../modals/ChangeJobTimeModal';
import get_job_typeEnum from '../../../common/get_job_type.enum';
import TawkTo from '../components/TawkTo';
import PromotedTag from '../foundation/PromotedTag';
import UserContext from '../context/user.context';
import { promoteJob } from '../../api/promotions.api';

export type UserJobsProps = {
    url: string
    user: LoggedInUser
    totalPromotions?: number
    totalAllowedJobs?: number
    totalJobs: number
    userPostedJobs?: PostedJob[],
    isUserRegistered: boolean,
    userHasExternalJobs: boolean,
    tilesMode?: boolean
}

const pageSize = 10;

const IconButtonWhiteBG = styled(IconButton)`
    background: white;
    margin-top: 1rem;
    border-radius: 5px;
    border: 1px solid ${props => props.theme.palette.secondary.main}a8;
`;

const sortOptions = [
    { value: 1, text: 'כותרת - עולה' },
    { value: 2, text: 'כותרת - יורד' },
    { value: 3, text: 'תאריך יצירה - עולה' },
    { value: 4, text: 'תאריך יצירה - יורד' },
    { value: 5, text: 'תאריך עדכון - עולה' },
    { value: 6, text: 'תאריך עדכון - יורד' },
    { value: 7, text: 'מספר פניות - עולה' },
    { value: 8, text: 'מספר פניות - יורד' },
];

const mapSortValueToType = {
    1: {
        orderby: 'title',
        direction: 'ASC',
    },
    2: {
        orderby: 'title',
        direction: 'DESC',
    },
    3: {
        orderby: 'created_at',
        direction: 'ASC',
    },
    4: {
        orderby: 'created_at',
        direction: 'DESC',
    },
    5: {
        orderby: 'updated_at',
        direction: 'ASC',
    },
    6: {
        orderby: 'updated_at',
        direction: 'DESC',
    },
    7: {
        orderby: 'numOfApplies',
        direction: 'ASC',
    },
    8: {
        orderby: 'numOfApplies',
        direction: 'DESC',
    },
};

const DEFAULT_SORTBY = 6;

const TagStyle = styled(VCenteredFlex)`
    border-radius: 5px;
    border: solid 1px #eeeeefa1;
    padding: 0.5rem;
    margin-inline-end: 0.5rem;
    margin-top: 0.5rem;
    display: inline-flex;
    background: #ffffff;
`;

const FACEBOOK_EXTERNAL_DOMAIN = 1;

// const INTERNAL_JOB_PERIOD = 30;

type UserJobItemProps = {
    postedJob: PostedJob
    // onChangeJobTimeClick: (job: PostedJob) => void
    onShowReportsClick: (job: PostedJob) => void
    onFreezeClick: (job: PostedJob) => void
    onUnFreezeClick: (job: PostedJob) => void
    onEditClick: (job: PostedJob) => void
    onDeleteClick: (job: PostedJob) => void
    onManageCampaignClick: (job: PostedJob) => void
    onManagePromotionClick: (job: PostedJob) => void
}

const UserJobItem = React.memo(({ postedJob, ...props }: UserJobItemProps) => {
    const dateDiff = ((new Date(postedJob.jobEnd) as any - Date.now()) / (86400000));
    const isAboutToExpire = dateDiff < 6 && dateDiff > 0;
    const isExpired = postedJob.jobEnd && dateDiff < 0;
    const user = React.useContext(UserContext);

    return <><BGContainer bgColor={isExpired ? '#ffe1e1' : ''}>
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={5}>
                <Anchor blank href={`/job/details/${postedJob.jobid}`} color="secondary" variant="h6" fontWeight="bold">{postedJob.title}</Anchor>
                <CustomBR h={10} />
                <Box display="flex">
                    {postedJob.promotion ? <><Button variant="contained" onClick={() => props.onManageCampaignClick(postedJob)}>נהל משרה</Button>&nbsp;&nbsp;</>
                        : <GoldenButton size="small" onClick={() => props.onManagePromotionClick(postedJob)}>קדם משרה</GoldenButton>}
                    {!!postedJob.promotion && <PromotedTag text="מקודם" />}
                </Box>
                <CustomBR h={10} />
                {!!postedJob.promotion && <TagStyle>מקודם עד:&nbsp;<DateFormat date={postedJob.jobEnd} /></TagStyle>}
                {!!(postedJob.user?.email || postedJob.email) && <TagStyle>אימייל:&nbsp;{postedJob.email || postedJob.user?.email}</TagStyle>}
                <TagStyle>ת. עדכון:&nbsp;<DateFormat date={postedJob.updated_at} showTime /></TagStyle>
                <Flexbox flexWrap="wrap">
                    {postedJob.frozen && <TagStyle>מודעה מוקפאת</TagStyle>}
                    {postedJob.employerJobid && <TagStyle><Typography><b>מספר משרה:</b> {postedJob.employerJobid}</Typography></TagStyle>}
                    {postedJob.externalDomain === FACEBOOK_EXTERNAL_DOMAIN && <TagStyle><Tooltip title="משרה זו נלקחה מהפייסבוק ושויכה לחשבון שלך">
                        <VCenteredFlex><Facebook display="inline" htmlColor="#1876f2" /> נמשך מהפייסבוק</VCenteredFlex>
                    </Tooltip></TagStyle>}
                </Flexbox>
            </Grid>
            <Grid item xs={12} sm={12} md={2} display="flex" justifyContent="center">
                <DateFormat date={postedJob.created_at} showTime />
            </Grid>
            {/* <Grid item xs={12} sm={12} md={2} display="flex" justifyContent="center">
            <DateFormat date={postedJob.updated_at} showTime />
        </Grid> */}
            <Grid item xs={12} sm={12} md={2}>
                {postedJob.jobEnd && <Box display="flex" justifyContent="center">
                    <DateFormat
                        color={isAboutToExpire ? 'red' : ''}
                        date={postedJob.jobEnd}
                        showTime
                    />
                </Box>}
                {isAboutToExpire && <Box display="flex" justifyContent="center">
                    <Typography color="red" fontWeight="bold">לקראת סיום</Typography>
                </Box>}
                {isExpired && <Box display="flex" justifyContent="center">
                    <Typography color="red" fontWeight="bold">הסתיים</Typography>
                </Box>}
            </Grid>
            <Grid item xs={12} sm={12} md={1} display="flex" justifyContent="center">
                <Anchor
                    title={`מספר מועמדויות: ${postedJob.appliedUsers}`}
                    href={`/job/appliedUsers/${postedJob.jobid}`}
                    text={postedJob.appliedUsers?.toString()}
                    fontWeight="bold" />
            </Grid>
            <Grid item xs={12} sm={12} md={2} display="flex" justifyContent="center">
                <div>
                    {/* <Button
                        onClick={() => props.onChangeJobTimeClick(postedJob)}
                        variant="outlined" fullWidth title="חידוש תקופת פרסום המשרה"><>
                            <Restore color="primary" />&nbsp;&nbsp;
                        נהל קמפיין
                        </></Button>
                    <CustomBR h={10} /> */}
                    <Button onClick={() => props.onShowReportsClick(postedJob)} variant="outlined" fullWidth><>
                        <InsertChartOutlined color="primary" />&nbsp;&nbsp;&nbsp;הצג דוחות
                    </></Button>
                    <Grid container spacing={2}>
                        <Grid alignItems="center" justifyContent="center" item xs={4}>
                            <IconButtonWhiteBG onClick={() => props.onDeleteClick(postedJob)} title="מחיקת משרה">
                                <DeleteOutline color="secondary" />
                            </IconButtonWhiteBG>
                        </Grid>
                        <Grid item xs={4}>
                            <IconButtonWhiteBG
                                disabled={!user?.admin && isExpired}
                                onClick={() => { if (user?.admin || !isExpired) props.onEditClick(postedJob); }}
                                title="עריכת משרה">
                                <EditOutlined color="secondary" />
                            </IconButtonWhiteBG>
                        </Grid>
                        <Grid item xs={4}>
                            {postedJob.frozen ? <IconButtonWhiteBG
                                disabled={isExpired}
                                onClick={() => props.onUnFreezeClick(postedJob)} title="הפעל משרה">
                                <PlayArrow color="secondary" />
                            </IconButtonWhiteBG> : <IconButtonWhiteBG
                                disabled={isExpired}
                                onClick={() => props.onFreezeClick(postedJob)} title="הקפא משרה"> <Pause color="secondary" />                            </IconButtonWhiteBG>}
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    </BGContainer><CustomBR /></>;
});

const AccountStats = styled(VCenteredFlex)`
    justify-content: space-evenly;
    color: white;
    /* background: ${props => props.theme.palette.primary.main}; */
    /* background: #ffc400; */
    background: ${props => props.theme.palette.secondary.main};
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    hr {
        background-color: white;
        margin: 0 1rem;
    }
`;

type State = {
    currentPage: number,
    totalJobs: number,
    postedJobs?: PostedJob[]
    filterText: string,
    sortby?: number
    totalPromotions: number
    totalAllowedJobs?: number
    campaignsModal: boolean,
    // startCampaignModal: boolean,
    buyCampaignsModal?: boolean,
    buyJobsModal?: boolean,
    jobTimeModal?: PostedJob
}

export default class UserJobs extends React.Component<UserJobsProps, State> {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            totalJobs: props.totalJobs,
            postedJobs: props.userPostedJobs,
            filterText: '',
            sortby: DEFAULT_SORTBY,
            campaignsModal: false,
            jobTimeModal: undefined,
            buyJobsModal: false,
            buyCampaignsModal: false,
            totalPromotions: props.totalPromotions,
            totalAllowedJobs: props.totalAllowedJobs,
        };
    }

    onGoToPage = async page => {
        this.setState({ currentPage: page }, this.filterJobs);
    };

    updateJob = async (jobid: number, data: PostedJob) => {
        const jobRes = await editJob({ jobid }, undefined, data, true);
        if (!jobRes) return;
        this.setState({
            postedJobs: this.state.postedJobs.map(
                job => (jobRes.data.jobid === job.jobid ? { ...job, ...jobRes.data } : job)),
        });
    };

    freezeJobClick = async (job: PostedJob) => {
        await this.updateJob(job.jobid, { ...job, frozen: true });
        if (this.props.tilesMode) {
            this.setState({
                totalAllowedJobs: job.promotion ? this.state.totalAllowedJobs : this.state.totalAllowedJobs + 1,
                totalPromotions: job.promotion ? this.state.totalPromotions + 1 : this.state.totalPromotions,
            });
        }
    };

    unfreezeJobClick = async (job: PostedJob) => {
        if (this.props.tilesMode) {
            if (!job.promotion && this.state.totalAllowedJobs <= 0) {
                this.setState({ buyJobsModal: true });
                return;
            }
            if (job.promotion && this.state.totalPromotions <= 0) {
                this.setState({ buyJobsModal: true });
                return;
            }

            await this.updateJob(job.jobid, { ...job, frozen: false });
            this.setState({
                totalAllowedJobs: job.promotion ? this.state.totalAllowedJobs : this.state.totalAllowedJobs - 1,
                totalPromotions: job.promotion ? this.state.totalPromotions - 1 : this.state.totalPromotions,
            });
        } else {
            await this.updateJob(job.jobid, { ...job, frozen: false });
        }
    };

    showReportsClick = (job: JobViewModel) => {
        window.location.assign(`/job/stats/${job.jobid}`);
    };

    editJobClick = (job: PostedJob) => { window.location.assign(`/user/editJob/${job.jobid}`); };

    onDeleteClick = async (job: PostedJob) => {
        await deleteJob({ jobid: job.jobid }, true);
        this.setState({
            postedJobs: this.state.postedJobs.filter(postedJob => postedJob.jobid !== job.jobid),
        });
        if (this.props.tilesMode) {
            this.setState({
                totalJobs: this.state.totalJobs - 1,
                totalAllowedJobs: job.promotion ? this.state.totalAllowedJobs : this.state.totalAllowedJobs + 1,
                totalPromotions: job.promotion ? this.state.totalPromotions + 1 : this.state.totalPromotions,
            });
        }
    };

    // onStartCampaign = async () => {
    //     await promoteJob({ jobid: this.startCampaignJob.jobid }, true);
    //     this.setState({
    //         postedJobs: this.state.postedJobs.map(postedJob => {
    //             if (postedJob.jobid === this.startCampaignJob.jobid) {
    //                 const endDate = new Date();
    //                 return {
    //                     ...postedJob,
    //                     promotion: 1,
    //                     jobStart: Date.now().toString(),
    //                     jobEnd: (new Date(endDate.setDate(endDate.getDate() + INTERNAL_JOB_PERIOD))).toString(),
    //                 };
    //             }
    //             return postedJob;
    //         }),
    //         totalJobs: this.state.totalJobs - 1,
    //         totalPromotions: this.state.totalPromotions - 1,
    //     });
    //     this.setState({ startCampaignModal: false });
    //     this.startCampaignJob = undefined;
    // };

    // onChangeJobTimeClick = (job: PostedJob) => {
    //     if (this.state.totalAllowedJobs <= 0 && this.state.totalPromotions <= 0) {
    //         this.setState({ buyJobsModal: true });
    //         return;
    //     }

    //     this.setState({ jobTimeModal: job });
    // }

    closeJobTimeModal = () => {
        this.setState({ jobTimeModal: undefined });
    };

    importFacebookJobs = () => {
        importExternalJobs({ userid: this.props.user.userid });
        window.location.reload();
    };

    filterJobs = async () => {
        const {
            sortby, filterText, currentPage,
        } = this.state;

        const sort = mapSortValueToType[sortby];
        const res = await getEmployerJobs({
            orderby: sort.orderby,
            direction: sort.direction,
            any: filterText,
            type: window.location.href.indexOf('admin') !== -1 ? get_job_typeEnum.INTERNAL_JOBS : get_job_typeEnum.USER_POSTED,
            start: (currentPage - 1) * pageSize,
        }, true);

        this.setState({
            postedJobs: res.data.rows,
            totalJobs: res.data.count,
        });
    };

    onFilterTextChangeDebounced = debounce(ev => {
        this.setState({ filterText: ev.target.value }, this.filterJobs);
    }, 500);

    onFilterTextChange = ev => {
        ev.persist();
        this.onFilterTextChangeDebounced(ev);
    };

    refreshOnClose: boolean;

    tokenGenerated = () => {
        this.refreshOnClose = true;
    };

    onCampaignModalClose = () => {
        this.setState({ campaignsModal: false });
        if (this.refreshOnClose) window.location.reload();
    };

    openCampaignsModal = () => {
        this.refreshOnClose = false;
        this.setState({ campaignsModal: true });
    };

    // startCampaignJob?: PostedJob

    onManageCampaignBtnClick = async (job: PostedJob) => {
        // if ((job.promotion > 0 && !this.state.totalPromotions)
        // || (job.promotion <= 0 && !this.state.totalAllowedJobs)) {
        //     this.setState({ buyJobsModal: true });
        //     return;
        // }
        if (!this.state.totalPromotions) {
            this.setState({ buyJobsModal: true });
            return;
        }

        await promoteJob({ jobid: job.jobid }, { jobType: jobTypes.promoted }, true);

        // this.startCampaignJob = job;
        this.setState({ jobTimeModal: job });
    };

    onPostJobClick = () => {
        if (!this.props.isUserRegistered) {
            window.showGlobalModal('משתמש לא מאומת', 'נראה כי כתובת המייל שלך עדיין לא מאומתת, על מנת לפרסם משרות יש ללחוץ על הקישור לאימות כתובת האימייל במייל שקיבלת מאיתנו.');
            return;
        }

        if (this.state.totalAllowedJobs <= 0 && this.state.totalPromotions <= 0) {
            this.setState({ buyJobsModal: true });
            return;
        }

        window.location.assign('/user/postJobs');
    };

    onCloseBuyJobsModal = () => {
        this.setState({ buyJobsModal: !this.state.buyJobsModal });
    };

    render() {
        const { props } = this;
        const {
            totalJobs, currentPage, filterText, sortby, postedJobs, campaignsModal,
            jobTimeModal,
            buyJobsModal,
        } = this.state;

        return <Page
            url={props.url}
            user={props.user}
            showBackToTop={false}
            pageHeaderTitle="Mploy - פאנל ניהול משרות" >
            <Container>
                <ChangeJobTimeModal
                    job={jobTimeModal}
                    totalAllowedJobs={this.state.totalAllowedJobs}
                    totalPromotions={this.state.totalPromotions}
                    open={!!jobTimeModal} onClose={this.closeJobTimeModal} />

                <DialogWithHeader open={buyJobsModal}
                    onClose={this.onCloseBuyJobsModal}
                    title="רכישת מודעות דרושים">
                    <>
                        <CustomBR />
                        <Typography>על מנת לפרסם או לקדם משרות יש לרכוש מודעות דרושים מקודמות תחילה.</Typography>
                        <CustomBR />
                        <Anchor text="לחץ כאן לרכישת משרות" blank href="/user/buyJobs" />
                        <CustomBR />
                    </>
                </DialogWithHeader>
                <DialogWithHeader maxWidth="lg" fullWidth open={campaignsModal}
                    gray
                    onClose={this.onCampaignModalClose} title="רכישת מודעות דרושים">
                    <BuyJobs onTokenGenerated={this.tokenGenerated} />
                </DialogWithHeader>
                {/* <DialogWithHeader fullWidth open={startCampaignModal}
                    firstActionText="ביטול"
                    onFirstActionClick={this.closeStartCampaignModal}
                    secondActionText="קידום משרה"
                    onSecondActionClick={this.onStartCampaign}
                    onClose={this.closeStartCampaignModal} title="קידום משרה">
                    <>
                        <Typography>מספר קמפיינים  לקידום בחשבון: <b>{this.state.totalPromotions || 0}</b></Typography>
                        <CustomBR />
                        <Typography>כותרת המשרה שתקודם: <b><u>{this.startCampaignJob?.title}</u></b></Typography>
                        <CustomBR />
                        <Typography>
                        במהלך הקמפיין המשרה תקודם למשך חודש החל מ:&nbsp;
                            <DateFormat date={(new Date()).toString()} showTime={false} /> ועד:&nbsp;
                            <DateFormat date={(() => {
                                const endDate = new Date();
                                return (new Date(endDate.setDate(endDate.getDate() + INTERNAL_JOB_PERIOD))).toString();
                            })()} showTime={false} />. בתקופה זו:
                        </Typography>
                        <CustomBR />
                        <VCenteredFlex><CheckBoxOutlined color="primary" /> המשרה תקודם בתוצאות החיפוש בתחומים ותפקידים רלוונטים</VCenteredFlex>
                        <VCenteredFlex><CheckBoxOutlined color="primary" /> המשרה תופיע בתוצאות החיפוש בצורה מודגשת ובולטת</VCenteredFlex>
                        <VCenteredFlex><CheckBoxOutlined color="primary" /> אנו נדאג לשלוח את המשרה בדיוור ישיר למועמדים רלוונטים</VCenteredFlex>
                        <CustomBR />
                    </>
                </DialogWithHeader> */}
                <CustomBR />
                {!props.isUserRegistered && <><UserNotification severity="error" id="is-user-registered"><>
                    <span>נראה כי כתובת המייל שלך עדיין לא מאומתת, </span>
                    <span>על מנת לפרסם משרות יש לגשת לעמוד <Anchor href="/user/profile" text="הפרופיל שלי" /> </span>
                    <span>ולסיים את תהליך ההרשמה.</span>
                </></UserNotification><CustomBR /></>}
                {props.userHasExternalJobs && <>{props.isUserRegistered ? <UserNotification severity="warning" id="import-jobs">
                    <Typography>המערכת שלנו מצאה משרות נוספות ברשת המשויכות לכתובת המייל: <b>{props.user.email}</b>.</Typography>
                    <Typography> תוכל לשייך משרות אלה לחשבון שלך בלחיצת כפתור.</Typography>
                    <CustomBR />
                    <Button onClick={this.importFacebookJobs} size="small">לחץ כאן לייבוא המשרות לחשבון שלך</Button>
                </UserNotification> : <UserNotification severity="warning" id="must-registered">
                    <Typography>
                    מצאנו משרות נוספות במערכת המשויכות לכתובת המייל: {props.user.email}, אך חשבונך אינו מאומת.
                על מנת לשייך את המשרות לחשבון שלך, יש ללחוץ על הקישור לאימות החשבון שנשלח אליך במייל בעת ההרשמה.
                    </Typography>
                    <CustomBR />
                    <Anchor blank href="/user/profile" text="הפרופיל שלי" />
                </UserNotification>}<CustomBR /></>}
                <CenteredFlex>
                    <Typography color="secondary" fontWeight="bold" variant="h4">סה"כ משרות :&nbsp;&nbsp;</Typography>
                    <SecondaryBox>
                        <Typography variant="h6" fontWeight="bold">
                            {totalJobs}
                        </Typography>
                    </SecondaryBox>
                </CenteredFlex>
                <><CustomBR /><AccountStats>
                    <CenteredFlex>
                        <AccountBalanceWalletOutlinedIcon />
                        <Tooltip title="כמות המשרות הרגילות שנותר בחשבונך לפרסום">
                            <Typography color="white">&nbsp;בנק משרות רגילות :&nbsp;&nbsp;&nbsp;</Typography>
                        </Tooltip>
                        <div>
                            <GreenTypography>{this.state.totalAllowedJobs || 0}</GreenTypography>
                        </div>
                    </CenteredFlex>
                    <Divider orientation="vertical" flexItem variant="fullWidth" />
                    <CenteredFlex>
                        <AccountBalanceWalletOutlinedIcon />
                        <Tooltip title="כמות המשרות המקודמות שנותר בחשבונך לפרסום">
                            <Typography color="white">&nbsp;בנק משרות מקודמות :&nbsp;&nbsp;&nbsp;</Typography>
                        </Tooltip>
                        <div>
                            <YellowTypography>{this.state.totalPromotions}</YellowTypography>
                        </div>
                    </CenteredFlex>
                </AccountStats></>
                <CustomBR />
                <Flexbox flexDirection="row-reverse">
                    <GoldenButton size="large" onClick={this.openCampaignsModal}>רכישת משרות</GoldenButton>
                    &nbsp;&nbsp;&nbsp;
                    <Button size="large" onClick={this.onPostJobClick} color="primary">פרסום משרה חדשה</Button>
                </Flexbox>
                <CustomBR />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            onChange={this.onFilterTextChange}
                            name="text-filter"
                            id="text-fitler"
                            type="text"
                            fullWidth
                            size="small"
                            variant="outlined"
                            label="סנן משרות לפי כותרת או מספר משרה"
                            InputProps={{
                                endAdornment: <FilterListIcon />,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Select
                            onChange={ev => this.setState({ sortby: parseInt(ev.target.value as string, 10) }, this.filterJobs)}
                            size="small"
                            name="sort-by"
                            fullWidth
                            defaultValue={DEFAULT_SORTBY}
                            options={sortOptions} />
                    </Grid>
                    <Grid item sm={2} />
                    <Grid item xs={12} sm={4}>
                        {totalJobs > 10 && <><Flexbox flexDirection="row-reverse"><Pagination
                            gotoPage={this.onGoToPage}
                            url={props.url}
                            pages={Math.ceil(totalJobs / pageSize)}
                            currentPage={currentPage}
                        /></Flexbox></>}
                    </Grid>
                </Grid>
                {(filterText || sortby) && <div>
                    <CustomBR />
                    {filterText && <Typography color="secondary" display="inline">
                    מסנן משרות לפי טקסט: <b>{filterText}</b>&nbsp;
                    </Typography>}
                    {sortby && <Typography color="secondary" display="inline">
                    סוג מיון: <b>{sortOptions.find(s => s.value === sortby).text}</b>
                    </Typography>}
                    <CustomBR />
                </div>}
                <ShadowBGContainer>
                    <Hidden lgDown>
                        <Grid container padding={2}>
                            <Grid fontWeight="bold" color="secondary" display="flex" justifyContent="center" item lg={5}>כותרת</Grid>
                            <Grid fontWeight="bold" color="secondary" display="flex" justifyContent="center" item lg={2}>תאריך יצירה</Grid>
                            {/* <Grid fontWeight="bold" color="secondary" display="flex" justifyContent="center" item lg={2}>תאריך עדכון</Grid> */}
                            <Grid fontWeight="bold" color="secondary" display="flex" justifyContent="center" item lg={2}>תאריך סיום</Grid>
                            <Grid fontWeight="bold" color="secondary" display="flex" justifyContent="center" item lg={1}>מס' פניות</Grid>
                            <Grid fontWeight="bold" color="secondary" display="flex" justifyContent="center" item lg={2}>פעולות</Grid>
                        </Grid>
                    </Hidden>
                    {postedJobs?.map(postedJob => <UserJobItem
                        // onChangeJobTimeClick={this.onChangeJobTimeClick}
                        onManagePromotionClick={this.onManageCampaignBtnClick}
                        onShowReportsClick={this.showReportsClick}
                        onFreezeClick={this.freezeJobClick}
                        onUnFreezeClick={this.unfreezeJobClick}
                        onEditClick={this.editJobClick}
                        onDeleteClick={this.onDeleteClick}
                        onManageCampaignClick={this.onManageCampaignBtnClick}
                        key={postedJob.jobid}
                        postedJob={postedJob}
                    />)}
                </ShadowBGContainer>
                <CustomBR />
                {totalJobs > 10 && <><Flexbox flexDirection="row-reverse"><Pagination
                    gotoPage={this.onGoToPage}
                    url={props.url}
                    pages={Math.ceil(totalJobs / pageSize)}
                    currentPage={currentPage}
                /></Flexbox><CustomBR /></>}
            </Container>
            <TawkTo />
        </Page>;
    }
}
