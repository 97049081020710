import { CompanyViewModel } from '../mappers/companyMapper';
import { CVViewModel, UserProfileViewModel } from './auth.types';
import { TagViewModel } from './tags.types';

export type JobViewModel = {
    jobid: number,
    title: string
    companyid?: number
    created_at: string
    createdby_userid?: number
    /**
     * 0 or 1
     */
    cvMandatory?: boolean | 0 | 1
    description: string
    employerJobid?: string
    formatted_address?: string
    frozen?: boolean
    hasEmail?: boolean
    lastedDaysBeforeExpire?: number
    latitude?: number
    longitude?: number
    phone: string
    promotion?: 0 | 1
    user?: UserProfileViewModel
    requirement?: string
    updated_at: string
    email?: string,
    tags?: TagViewModel[]
    company?: CompanyViewModel
    externalDomain?: number
}

export const jobTypes = {
    /**
     * just a regular internal job. promotion is 0
     */
    regular: 1,
    /**
     * promoted job, with promotion 1 (in jobs table)
     */
    promoted: 2,

} as const;

export type JobTypesKeys = keyof typeof jobTypes;
export type JobTypesValues = typeof jobTypes[JobTypesKeys]

export type PostedJob = Omit<JobViewModel, 'tags'> & {
    appliedUsers: number
    jobStart?: string
    jobEnd?: string
    jobType?: JobTypesValues
}

export type AppliedJobViewModel = {
    employerState: 0 | 1 | 2 | 3 | 4 | 5,
    createdAt: string
    hasCV: boolean
    job: JobViewModel,
    userCV: CVViewModel
}

export type SavedJobViewModel = {
    createdAt: string
    job: JobViewModel,
}

export type JobSearchResultViewModel = JobViewModel & {
    company?: CompanyViewModel
    /**
     * the distance from user location / search location
     */
    distance?: number,
    /**
     * 0 or 1
     */
    isUserSaved?: number,
    userToJobState?: number
    userToJobStateDate?: string
}
